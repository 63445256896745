import React from 'react'
import ThemeCards from './themeCards'
import Reinsurance from './reinsurance'
import Actors from './actors'
import TopProducts from './topProducts'
import EventTiles from './eventTiles'
import Slider from './slider'
import { visit } from './utils'

import './homePage.scss'

const HomePage = ({ topProducts, topShows }) => {
  // useScript('https://widgets.rr.skeepers.io/generated/0a03283c-e9d5-54b4-1905-6f23e2863790/8a1fa782-496d-4bc4-a65e-eaee61b43848.js', { removeOnUnmount: true })

  const baseUrl = `${window.location.protocol}//${window.location.host}`

  const handleClick = () => {
    visit(`${baseUrl}/coffrets-cadeaux?all=true`)
  }

  return (
    <div className="home-page-container">
      <Slider />
      <Reinsurance />
      <TopProducts data={topProducts} />
      <ThemeCards />
      <Actors />
      <EventTiles data={topShows} listId={'homepage_spotlight_shows'} listName={'Spectacles du moment'} />

      <div id="reviews" className="home-page-avis-verifies">
        <iframe className="avis-verifies-logo" frameBorder="0" width="300" height="171"
                src="//cl.avis-verifies.com/fr/cache/0/a/0/0a03283c-e9d5-54b4-1905-6f23e2863790/widget4/0a03283c-e9d5-54b4-1905-6f23e2863790index.html"
                loading="lazy"></iframe>
        <iframe className="avis-verifies-frame" frameBorder="0"
                src="//cl.avis-verifies.com/fr/cache/0/a/0/0a03283c-e9d5-54b4-1905-6f23e2863790/widget4/0a03283c-e9d5-54b4-1905-6f23e2863790horizontal_index.html"
                loading="lazy"></iframe>
      </div>
    </div>
  )
}

export default HomePage
