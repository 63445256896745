import React, { useState } from 'react'
import CustomMenu from './customMenu'
import { visit } from './utils'

import './headerTabs.scss'

const HeaderTabsMobile = ({ tab, active }) => {
  const [toggleSubMenu, setToggleSubMenu] = useState(false)
  const baseUrl = `${window.location.protocol}//${window.location.host}`
  const tabUrl = baseUrl + tab.path
  const hasCustomMenu = typeof tab.content !== 'undefined' && tab.content.length > 0
  const linkClassName = []

  if (active) linkClassName.push('active-path')
  if (tab.bold) linkClassName.push('bold')

  switch (tab.color) {
    case 'red':
      linkClassName.push(tab.color)
      break
  }

  const handleClickMobileTab = () => {
    if (hasCustomMenu) {
      setToggleSubMenu(!toggleSubMenu)
    } else {
      visit(tabUrl)
    }
  }

  const customMenuClassName = ['subMenu']
  if (!toggleSubMenu) customMenuClassName.push('hidden')

  return (
    <>
      <div className="--citc-header-tabs mobile">
        <a className={linkClassName.join(' ')} onClick={handleClickMobileTab} href={!hasCustomMenu ? tabUrl : null}>
          {tab.prefix}{tab.title}{tab.suffix}
          {hasCustomMenu && <span className="icon dropdown-menu">&#xec03;</span>}
        </a>
        {hasCustomMenu &&
          <div className={customMenuClassName.join(' ')}>
            {tab.content.filter(content => content.type === 'list').map((content, key) => (
              <div key={key}>
                <h1>{content.title}</h1>
                <CustomMenu data={content.content} />
              </div>
            ))}
          </div>
        }
      </div>
    </>
  )
}

export default HeaderTabsMobile
